<template>
  <div class="container">
    <div class="title">
      <div class="name">{{$t('exchange1')}}</div>
      <img src="@/image/tab8.png" alt="" />
    </div>
    <div class="infos">
      <div class="info-item">
        <div class="titles">
          <span>{{$t('exchange2')}}{{returnBalance(coin[chooseIndex1].balance)}})</span>
          <div class="num" @click="allIn">MAX</div>
        </div>
        <div class="inputs">
          <popover
            v-model="showPopover1"
            theme="dark"
            trigger="click"
            :actions="coin"
            @select="onSelect1"
          >
            <template #reference>
              <div class="logo">
                <img :src="coin[chooseIndex1].icon" alt="">
                <span class="name">{{coin[chooseIndex1].text}}</span>
              </div>
            </template>
          </popover>
          <input type="number" v-model="coin[chooseIndex1].num" @input="returnCoin1">
        </div>
      </div>
      <div class="change">
        <img class="imgs" src="@/image/refresh.png" :class="clickImg ? 'animation' : ''" alt="" @click="switchCoin">
      </div>
      <div class="info-item">
        <div class="titles">
          <span>{{$t('exchange3')}}</span>
        </div>
        <div class="inputs">
          <popover
            v-model="showPopover2"
            theme="dark"
            trigger="click"
            :actions="coin1"
            @select="onSelect2"
          >
            <template #reference>
              <div class="logo">
                <img :src="coin1[chooseIndex2].icon" alt="">
                <span class="name">{{coin1[chooseIndex2].text}}</span>
              </div>
            </template>
          </popover>
          <input type="number" v-model="coin1[chooseIndex2].num" @input="returnCoin0">
        </div>
      </div>
      <div class="btns" @click="doExchange">{{$t('exchange1')}}</div>
    </div>
  </div>
</template>

<script>
import Web3 from '@/utils/web3.js';
import BigNumber from "bignumber.js";
import { Popover } from 'vant';
export default {
  name: 'exchange',
  components: {
    Popover
  },
  data() {
    return{
      showPopover1: false,
      showPopover2: false,
      clickImg: false,
      num: 0,
      coin: [
        {
          text: 'USDT',
          icon: require('@/image/usdt.png'),
          num: '0',
          balance: '',
          total: '',
          address: '0x55d398326f99059ff775485246999027b3197955'
        },{
          text: 'EGA',
          icon: require('@/image/coin.png'),
          num: '0',
          balance: '',
          total: '',
          address: '0xD151e5F58B6dAd0EC955e7148735C96F4e3B2ffa'
        },
      ],
      coin1: [
        {
          text: 'BNB',
          icon: require('@/image/bnb.png'),
          num: '0',
          balance: '',
          total: '',
          address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
        }
      ],
      chooseIndex1: 0,
      chooseIndex2: 0,
      usdtContract: '0x55d398326f99059ff775485246999027b3197955',
      wbnbContract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      egaContract: '0xD151e5F58B6dAd0EC955e7148735C96F4e3B2ffa',
      swapRouterContract: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
			usdt_instance: '',
			wbnb_instance: '',
			ega_instance: '',
			wbnbSwap_instance: '',
			egaSwap_instance: '',
      swapRouter_instance: '',
    }
  },
	mounted() {
		this.init();
	},
  methods: {
    onSelect1(e,i) {
      this.chooseIndex1 = i;
    },
    onSelect2(e,i) {
      this.chooseIndex2 = i;
    },
    switchCoin() {
      const useCoin = [...this.coin];
      const useCoin1 = [...this.coin1];
      this.coin = useCoin1;
      this.coin1 = useCoin;
      this.clickImg = !this.clickImg;
      let index1 = this.chooseIndex1;
      let index2 = this.chooseIndex2;
      this.chooseIndex1 = index2;
      this.chooseIndex2 = index1;
    },
		init() {
			Web3.getWeb3().then(async res => {
				this.web3js = res;
				try {
					const result = await window.ethereum.enable();
					this.address = result[0];
					this.$store.commit("setAddress", this.address);
					this.getAccount();
				} catch (error) {
					this.$toast(this.$t('toast13'))
				}
			}).catch(err => {
				this.$toast(err.message)
			})
		},
    async getAccount() {
			const usdtabi = require('@/utils/usdt.json');
			const wbnbabi = require('@/utils/usdt.json');
			const egaabi = require('@/utils/usdt.json');
			const swapRouterabi = require('@/utils/swapRouter.json');
			this.usdt_instance = await new this.web3js.eth.Contract(usdtabi, this.usdtContract, {
					from: this.address,
			});
			this.wbnb_instance = await new this.web3js.eth.Contract(wbnbabi, this.wbnbContract, {
					from: this.address,
			});
			this.ega_instance = await new this.web3js.eth.Contract(egaabi, this.egaContract, {
					from: this.address,
			});
			this.swapRouter_instance = await new this.web3js.eth.Contract(swapRouterabi, this.swapRouterContract, {
					from: this.address,
			});

			this.usdt_instance.methods.balanceOf(this.address).call().then(v => {
        this.coin.map(item => {
          if (item.text == 'USDT') {
            item.balance = Number(v)/Math.pow(10,18);
          }
        })
			})
      this.web3js.eth.getBalance(this.address).then(res => {
        this.coin1[0].balance = Number(res)/Math.pow(10,18);
      })
			this.ega_instance.methods.balanceOf(this.address).call().then(v => {
				this.coin.map(item => {
          if (item.text == 'EGA') {
            item.balance = Number(v)/Math.pow(10,18);
          }
        })
			})
		},
    doExchange() {
      if (this.coin[this.chooseIndex1].num > this.coin[this.chooseIndex1].balance) return this.$toast(this.$t('toast4'))
      if (this.coin[this.chooseIndex1].text == 'BNB') {
        this.transBNB();
      } else if (this.coin[this.chooseIndex1].text == 'EGA') {
        this.ega_instance.methods.allowance(this.address,this.swapRouterContract).call().then(res => {
          if (res == 0) {
            this.ega_instance.methods.approve(this.swapRouterContract,'115792089237316195423570985008687907853269984665640564039457584007913129639935').send().then(res => {
              this.transIt();
            }).catch(err => {
              this.$toast(err.message)
            })
          } else {
            this.transIt(); 
          }
        })
      } else {
        this.usdt_instance.methods.allowance(this.address,this.swapRouterContract).call().then(res => {
          if (res == 0) {
            this.usdt_instance.methods.approve(this.swapRouterContract,'115792089237316195423570985008687907853269984665640564039457584007913129639935').send().then(res => {
              this.transIt();
            }).catch(err => {
              this.$toast(err.message)
            })
          } else {
            this.transIt();
          }
        })
      }
    },
    getParenthesesStr(text) {
      let result = ''
      let regex = /\((.+?)\)/g;
      let options = text.match(regex)
      if (options[0]) {
        let option = options[0]
        result = option.substring(1, option.length - 1)
        return result
      } else {
        return ''
      }
    },
    toHexData(info) {
      let dataStr = '';
      let argsArr = info.dataArr;
      if (argsArr.length>0) {
        dataStr = this.web3js.eth.abi.encodeFunctionSignature(info.methodStr) + this.web3js.utils.stripHexPrefix(this.web3js.eth.abi.encodeParameters(this.getParenthesesStr(info.methodStr).split(','), argsArr));
      } else {
        dataStr = this.web3js.eth.abi.encodeFunctionSignature(info.methodStr)
      }
      return dataStr
    },
    transBNB() {
      this.$loading.show();
      let timeStr = (parseInt(new Date().getTime()/1000)+1200);
      let queryInfo = {
        methodStr: 'swapExactETHForTokensSupportingFeeOnTransferTokens(uint256,address[],address,uint256)',
        dataArr: [0,[this.coin[this.chooseIndex1].address,this.coin1[this.chooseIndex2].address],this.address,timeStr]
      }
      let queryStr = this.toHexData(queryInfo);
      this.web3js.eth.sendTransaction({
        from: this.address,
        to: this.swapRouterContract,
        value: BigNumber(Number(this.coin[this.chooseIndex1].num)*Math.pow(10,18)).toFixed(0),
        data: queryStr
      }).then(res => {
        this.$loading.hide();
        this.getAccount();
        this.$toast(this.$t('toast10'));
      }).catch(err => {
        this.$loading.hide();
        this.$toast(err.message)
      })
    },
    transIt() {
      this.$loading.show();
      let timeStr = (parseInt(new Date().getTime()/1000)+1200);
      this.swapRouter_instance.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
        BigNumber(Number(this.coin[this.chooseIndex1].num)*Math.pow(10,18)).toFixed(0),
        0,
        [this.coin[this.chooseIndex1].address,this.coin1[this.chooseIndex2].address],
        this.address,
        timeStr
      ).send().then(res => {
        this.$loading.hide();
        this.getAccount();
        this.$toast(this.$t('toast10'));
      }).catch(err => {
        this.$loading.hide();
        this.$toast(err.message)
      })
    },
		returnBalance(val) {
			return parseFloat((Math.floor(val*1000000)/1000000).toFixed(6))
		},
    allIn() {
      this.coin[this.chooseIndex1].num = Math.floor(this.coin[this.chooseIndex1].balance*1000)/1000;
      this.returnCoin1();
    },
    returnCoin1() {
      if (this.coin[this.chooseIndex1].num) {
        if (parseFloat(this.coin[this.chooseIndex1].num) > 0) {
          this.swapRouter_instance.methods.getAmountsOut(BigNumber(Number(this.coin[this.chooseIndex1].num)*Math.pow(10,18)).toFixed(0),[this.coin[this.chooseIndex1].address,this.coin1[this.chooseIndex2].address]).call().then(res => {
            this.coin1[this.chooseIndex2].num = (Math.floor((Number(res[1])/Math.pow(10,18))*1000000)/1000000)
          }).catch(err => {
            this.$toast(err.message)
          })
        }
      } else {
        this.coin1[this.chooseIndex2].num = ''
      }
    },
    returnCoin0() {
      if (this.coin1[this.chooseIndex2].num) {
        if (parseFloat(this.coin1[this.chooseIndex2].num) > 0) {
          this.swapRouter_instance.methods.getAmountsOut(BigNumber(Number(this.coin1[this.chooseIndex2].num)*Math.pow(10,18)).toFixed(0),[this.coin[this.chooseIndex1].address,this.coin1[this.chooseIndex2].address]).call().then(res => {
            this.coin[this.chooseIndex1].num = (Math.floor((Number(res[1])/Math.pow(10,18))*1000000)/1000000)
          }).catch(err => {
            this.$toast(err.message)
          })
        }
      } else {
        this.coin[this.chooseIndex1].num = ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.container{
  padding: 0 20px;
  .title {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .name {
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
    }
    img {
      width: 18px;
      margin-left: 10px;
    }
  }
  .infos{
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px 35px;
    background: #3B3F49;
    border-radius: 15px;
    .info-item{
      width: 100%;
      box-sizing: border-box;
      background: #18191D;
      border-radius: 15px;
      padding: 15px 15px 0;
      &:last-child{
        margin-top: -10px;
      }
      .titles{
        line-height: 1;
        font-size: 11px;
        color: #575961;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .num{
          width: 44px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(to right,#ccec44,#fdfdff);
          border-radius: 8px;
          font-size: 11px;
          color: #18191D;
          font-weight: bold;
        }
      }
      .inputs{
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
          display: flex;
          align-items: center;
          img{
            width: 34px;
            height: 34px;
            margin-right: 10px;
            border-radius: 50%;
          }
          .name{
            font-size: 16px;
            color: #FFFFFF;
            font-weight: bold;
          }
        }
        input{
          width: 50%;
          font-size: 16px;
          color: #FFFFFF;
          text-align: right;
          background: transparent;
          border: none;
        }
      }
    }
    .change{
      width: 35px;
      height: 35px;
      background: #18191D;
      border: 4px solid #3B3F49;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin: -10px auto;
      position: relative;
      .imgs{
        width: 15px;
        height: 15px;
        transition: transform .5s;
        &.animation {
          transform: rotate(180deg);
        }
      }
    }
  }
  .btns{
    width: 100%;
    height: 40px;
    background: linear-gradient(to right,#ccec44,#fdfdff);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #18191D;
    margin-top: 25px;
  }
}
.van-icon img{
  border-radius: 50% !important;
}
</style>